<template>
  <div
    class="page"
    id="cs-privacy-policy-page"
    :class="{
      'more-depth':
        ($route.meta && $route.meta.headerType === 'more-depth') || $route.params.headerType === 'more-depth',
    }"
  >
    <div class="container">
      <div class="page-header">
        <div class="page-header__title">{{ $__t('개인정보 처리방침') }}</div>
      </div>

      <div class="page-body">
        <notion-renderer block-id="8a7f6926322a4709a6cbfd3bac030d38" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: this.$store.state.lang === 'ko' ? 'ko' : 'en'
    };
  }
};
</script>

<style lang="scss" scoped>

</style>

